import { Article } from '@/models/Article';

export const ArticleRequest = (argArticle) => {
  const article = argArticle.copy()

  if (!article.id) {
    delete article.id
  }

  if (article.category) {
    article.category = article.category.id
  }

  return article
}

export const ArticleResponse = (article) => {
  return new Article(article)
}

export const ArticlesResponse = (articles) => {
  return articles.map((a) => ArticleResponse(a))
}