
import Pagination from '@/models/Pagination';
import BaseService from './BaseService';
import { ArticleRequest, ArticleResponse, ArticlesResponse } from './mappers/ArticleMapper';

class ArticleService extends BaseService {

    constructor() {
        super();
    }

    getArticles(pagination, sorting) {
        let p = pagination;
        let s = sorting;
        return this.get(`articles?page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`)
                    .then(response => response.data)
                    .then(response => {
                        let articles = ArticlesResponse(response.results);
                        let pagination = new Pagination(response);

                        return { articles, pagination };
                    });
    }

    getArticle(id) {
        return this.get(`articles/${id}`)
                   .then(response => ArticleResponse(response.data));
    }

    deleteArticle(id) {
        return this.delete(`articles/${id}`);
    }

    createArticle(data) {
        return this.post(`articles`, ArticleRequest(data));
    }

    updateArticle(id, data) {
        return this.patch(`articles/${id}`, ArticleRequest(data));
    }

    getPublicPublishedArticles(pagination, sorting) {
        let p = pagination;
        let s = sorting;
        return this.get(
            `public/articles/published?page=${p.page}&per_page=${p.pageSize}&sort=${s.sort}`
        )
            .then(response => response.data)
            .then(response => {
                let articles = ArticlesResponse(response.results);
                let pagination = new Pagination(response);

                return { articles, pagination };
            });
    }

    getPublicArticle(id) {
        return this.get(`public/articles/${id}`)
                   .then(response => ArticleResponse(response.data));
    }
}

export default new ArticleService();
