import BaseModel from "@/models/BaseModel";

export class File extends BaseModel {
    constructor({
        id,
        file_path = null // Binary file in POST, file path in response.
        } = {}) {
        super();

        this.id = id;
        this.file_path = file_path;
    }
}

export default File;
